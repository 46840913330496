<template>
  <div>
    <div class="text-title center">Modificar Contraseña</div>

    <v-row justify="center" class="mt-6">
      <v-card class="pa-7" width="550">
        <div class="mr-3" v-if="!passwordChanged">La nueva contraseña debe incluir:</div>
        <div class="text-list pa-0 mb-6 ma-0" v-if="!passwordChanged">
          <v-col>
            <ul>
              <li>Un mínimo de nueve caracteres</li>
              <li>Al menos un número</li>
              <li>Al menos una letra mayúscula</li>
              <li>Al menos una letra minúscula</li>
              <li>Al menos un carácter especial (!, @, #, $, %, ^, &, *)</li>
            </ul>
          </v-col>
        </div>
        <v-form v-model="valid" ref="form" v-if="!passwordChanged">
          <v-row>
            <v-col>
              <v-text-field
                label="Ingresa tu contraseña actual"
                name="oldPassword"
                :type="showPassword ? 'text' : 'password'"
                v-model="oldPassword"
                :rules="[required('Contraseña actual')]"
                required
                outlined
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Ingresa tu contraseña nueva"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                :rules="[required('Contraseña nueva'), validatePassword()]"
                required
                outlined
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Vuelve a ingresar tu nueva contraseña"
                name="confirmPassword"
                :type="showPassword ? 'text' : 'password'"
                v-model="passwordConfirm"
                :rules="[required('Confirmación de contraseña'), validatePassword()]"
                :error-messages="passwordMatchError()"
                required
                outlined
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
          </v-row>

          <v-row justify="center" class="roboto">
            <v-col class="pa-0 ma-0" order="2" order-md="1" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <v-btn text rounded width="100%" color="tway-violet" @click="volver()"> Volver </v-btn>
            </v-col>
            <v-col class="pa-0 ma-0 ml-md-4 mb-4" order="1" order-md="2" cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
              <v-btn rounded width="100%" color="tway-violet" class="white--text" @click="changePassword">
                Modificar Contraseña
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-card-title v-if="passwordChanged">
          <v-row class="ma-0 pa-0" justify="center">
            <v-icon color="#16C62E" size="95"> mdi-check-circle </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text
          class="headline tway-gray-dark--text mb-4"
          style="font-size: 18px !important"
          v-if="passwordChanged"
        >
          <v-row class="ma-0 pa-0 text-center" justify="center">
            <span> La contraseña se ha modificado exitosamente. </span>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="passwordChanged">
          <v-col class="text-center">
            <v-btn
              rounded
              color="tway-violet"
              class="elevation-2 pa-6 mb-2 white--text roboto"
              :to="{ name: 'my-profile' }"
            >
              ACEPTAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import ValidatorsService from "@/services/validatorsService";
import currentUserService from "@/services/currentUserService";

export default {
  data() {
    return {
      valid: true,
      oldPassword: "",
      password: "",
      passwordConfirm: "",
      showPassword: false,
      passwordChanged: false,
    };
  },

  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),

    validatePassword: () => ValidatorsService.validatePassword(),

    passwordMatchError: function () {
      return this.password === this.passwordConfirm ? "" : "Contraseñas deben ser iguales";
    },

    volver() {
      this.$router.push({ name: "my-profile" });
    },

    changePassword() {
      if (this.$refs.form.validate()) {
        let passwords = {
          oldPassword: this.oldPassword,
          password: this.password,
          passwordConfirm: this.passwordConfirm,
        };

        currentUserService
          .changePassword(passwords)
          .then(() => (this.passwordChanged = true))
          .catch((err) => {
            this.$log.error(err);
            this.password = "";
            this.oldPassword = "";
            this.passwordConfirm = "";
            this.passwordChanged = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  color: #333333;
}
.text-list {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
}

.border.v-input .v-input__slot {
  border: 1px solid #7319d5;
  box-sizing: border-box;
  border-radius: 4px;
}

.v-text-field--outlined fieldset {
  border-color: #7319d5 !important;
}
</style>
